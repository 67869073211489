@tailwind components;
@tailwind utilities;
@tailwind base;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Reset box model */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
} /* Reset margins and paddings */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
figure,
blockquote {
  margin: 0;
  padding: 0;
} /* Reset font sizes */
html {
  font-size: 100%;
}
body {
  font-size: 1rem;
} /* Reset list styles */
ol,
ul {
  list-style: none;
}
