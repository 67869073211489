table {
    border-collapse: collapse;
    border-top: 0.01px solid #aaa;
    border-left: 0.01px solid #aaa;
    margin: auto;
  }
  
  th,
  td {
    border-right: 0.01px solid #aaa;
    border-bottom: 0.01px solid #aaa;
    padding: 4px 8px;
  }
  
  td {
    text-align: left;
  }
  
  .save-button-span {
    display: flex;
    flex-flow: row-reverse nowrap;
    margin: 1em 0 1em 0;
  }
  
  .medplum-button {
    height: 3em;
    font-size: var(--medplum-font-big);
  }
  
  .content-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .highlighted-row {
    background-color: #ce61ebad;
  }
  
  .color-BDO-5A-RO-BY {
    background-color: #ce61ebad;
  }
  
  .color-BDO-2-2A-RO-TT {
    background-color: #ff8f33;
  }
  
  .color-BDO-MONITOR-RO-TT {
    background-color: #18ca42;
  }
  