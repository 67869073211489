$icons: aggregation, arrows, asc, cancel, chart, checkbox-checked,
  checkbox-indeterminate, checkbox-unchecked, color-picker, columns, contracted,
  copy, cross, csv, desc, excel, expanded, eye-slash, eye, filter, first, grip,
  group, last, left, linked, loading, maximize, menu, minimize, next, none,
  not-allowed, paste, pin, pivot, previous, radio-button-off, radio-button-on,
  right, save, small-down, small-left, small-right, small-up, tick, tree-closed,
  tree-indeterminate, tree-open, unlinked;

$icon-path: 'assets/icons/';

@mixin icon-background($path, $icon, $size) {
  background: url('#{$path}#{$icon}.svg') no-repeat;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: $size $size;
  height: $size;
  width: $size;
}

.ag-theme-alpine {
  .ag-icon {
    display: inline-block;
  }

  @each $icon in $icons {
    .ag-icon.ag-icon-#{$icon} {
      @include icon-background($icon-path, $icon, 16px);
      &:before {
        content: '' !important;
      }
    }
  }

  .ag-icon.ag-icon-row-drag {
    @include icon-background($icon-path, 'grip', 16px);
    &:before {
      content: '' !important;
    }
  }

  .ag-checkbox-input-wrapper {
    @include icon-background($icon-path, 'checkbox-unchecked', 16px);
    &:after {
      content: '' !important;
    }
  }

  .ag-checkbox-input-wrapper.ag-checked {
    @include icon-background($icon-path, 'checkbox-checked', 16px);
    &:after {
      content: '' !important;
    }
  }

  .ag-checkbox-input-wrapper.ag-indeterminate {
    @include icon-background($icon-path, 'checkbox-indeterminate', 16px);
    &:after {
      content: '' !important;
    }
  }
}

.ag-header-cell {
  padding: 5px !important;
  font-size: 7pt;
}
